import React, { useEffect, useRef, useState } from 'react';
import { AddressToDistrict } from './addrtodistrict';
import { DistrictMap } from './district-map';
import classNames from 'classnames';
import TitleSpacer from '../titleSpacer';

const TRUE_CENTER = { lat: 37.76848031290581, lng: -122.44583602229383 };

// This center is slightly offset to allow space off to the right for the info card on larger screens.
const OFFSET_CENTER = { lat: 37.755995, lng: -122.387471 };
const DEFAULT_ZOOM = 12;
const SMALL_SCREEN_MIN_PX = 640;

export const DistrictFinder = ({
  geoJsonUrl,
  infoComponent,
  fetchRemoteInfo,
  headerText,
  addrClasses,
  mapClasses,
  forceTrueCenter,
  isLinkAvailable,
  focusedZoom,
  allowClick = true,
  showAddressInput = true,
}) => {
  let center = forceTrueCenter
    ? TRUE_CENTER
    : typeof window !== 'undefined' && window.screen.width < SMALL_SCREEN_MIN_PX
    ? TRUE_CENTER
    : OFFSET_CENTER;
  let [state, setState] = useState({
    districtInfo: null,
    center: center,
    zoom: DEFAULT_ZOOM,
    hasPoint: false,
    changeCenterLoading: false,
  });
  const infoElementRef = useRef();

  const onCloseHandler = () => {
    setState((prevState) => ({
      ...prevState,
      districtInfo: null,
      hasPoint: false,
    }));
  };

  let infoElement = React.createElement(infoComponent, {
    info: state.districtInfo,
    onCloseHandler,
    isLinkAvailable,
  });

  useEffect(() => {
    if (infoElementRef.current && state.districtInfo) {
      infoElementRef.current.scrollIntoView({
        behavior: 'smooth',
        // These two ensure it's aligned to the bottom of the screen.
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [state.districtInfo]);

  const handleDistrictChanged = (info, type = 'default') => {
    let newState = {};
    console.log('Handle district changed: ', info);
    if (info) {
      newState = { districtInfo: info, hasPoint: false, changeCenterLoading: false };
      if (info.position && type === 'default') {
        newState = { ...newState, center: info.position, hasPoint: true };
      }
    }
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  let selectedDistricts = [];
  if (state.districtInfo) {
    if (state.districtInfo.supervisor?.districtNum) {
      selectedDistricts.push(state.districtInfo.supervisor.districtNum);
    }
    if (state.districtInfo.assembly?.district) {
      selectedDistricts.push(parseInt(state.districtInfo.assembly.district));
    }
  }

  return (
    <div className="mx-auto bg-white">
      <div
        className={classNames('relative mx-4 md:mx-0 drop-shadow-2xl z-10', {
          hidden: !showAddressInput,
        })}
      >
        <div
          className={classNames(
            'bg-white mx-auto flex flex-wrap items-center p-6 gap-2 md:gap-6',
            addrClasses
          )}
        >
          <div className="w-auto max-w-md sm:flex-none">
            <div className="flex-col gap-2 sm:break-inside-avoid">
              <TitleSpacer />
              <div className="text-xl font-bold capitalize text-left">{headerText}</div>
            </div>
          </div>
          <div className="w-auto flex-auto min-w-[50%] break-inside-avoid">
            <AddressToDistrict
              onDistrictChanged={handleDistrictChanged}
              changeCenterLoading={state.changeCenterLoading}
              toggleCenterLoading={() =>
                setState((prev) => ({ ...prev, changeCenterLoading: !prev.changeCenterLoading }))
              }
            />
          </div>
        </div>
      </div>

      <div
        className={classNames('mx-auto relative -mt-8 md:-mt-0 grid drop-shadow-xl', mapClasses, {
          'grid-cols-1 md:grid-cols-2': selectedDistricts.length > 0,
        })}
      >
        <DistrictMap
          districtSelectHandler={(d) => {
            if (!fetchRemoteInfo) {
              return handleDistrictChanged(d);
            }
            fetch(`/.netlify/functions/district?district=${d}`, {
              method: 'GET',
            }).then((response) => {
              response.json().then(handleDistrictChanged);
            });
          }}
          center={state.center}
          zoom={state.zoom}
          hasPoint={state.hasPoint}
          selectedDistricts={selectedDistricts}
          geoJsonUrl={geoJsonUrl}
          allowClick={allowClick}
        />
        <div ref={infoElementRef}>{infoElement}</div>
      </div>
    </div>
  );
};

export default DistrictFinder;
