import React from 'react';
import DistrictFinder from './district-finder';
import { SupervisorsByName } from '../electeds/supervisors.js';
import { XIcon } from '@heroicons/react/outline';
import { Supe } from './supe';

const GEOJSON = '/districts/supervisors.geojson';

const CloseButton = ({ onCloseHandler }) => {
  return (
    <div
      className="p-5 text-slate-500 hover:text-red-500 rounded-full cursor-pointer text-xl sm:px-2"
      onClick={onCloseHandler}
    >
      <XIcon className="w-5 h-5" />
    </div>
  );
};

const DistrictInfo = ({ info, onCloseHandler, isLinkAvailable }) => {
  let inner;
  if (info === null) {
    inner = <div></div>;
  } else if (Object.keys(info.supervisor).length === 0) {
    inner = <div>Sorry, something went wrong.</div>;
  } else {
    const supeInfo = info.supervisor;
    inner = (
      <div className="flex-col">
        <div className="w-11/12">
          <Supe
            supervisor={SupervisorsByName[`${supeInfo.firstName} ${supeInfo.lastName}`]}
            isLinkAvailable={isLinkAvailable}
            map={map}
          />
        </div>
        <div className="absolute top-1 right-2">
          <CloseButton onCloseHandler={onCloseHandler} />
        </div>
      </div>
    );
  }
  let hasInfo = info ? 'h-[28rem] md:h-[32rem] bg-white flex relative' : '';
  return <div className={`${hasInfo}`}>{inner}</div>;
};

export const SupervisorDistrictFinder = ({ isLinkAvailable }) => {
  return (
    <div className="mx-auto w-full px-5 max-w-7xl sm:px-6 lg:px-8 xl:px-0">
      <DistrictFinder
        geoJsonUrl={GEOJSON}
        isLinkAvailable={isLinkAvailable}
        infoComponent={DistrictInfo}
        fetchRemoteInfo={true}
        headerText="Enter your address to find your Supervisor"
        focusedZoom={15}
      />
    </div>
  );
};

export default SupervisorDistrictFinder;
