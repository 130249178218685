import React from 'react';
import greenArrow from '../images/green-arrow.svg';

const TitleSpacer = () => {
  return (
    <div className="flex gap-[2px]">
      <img src={greenArrow} alt="" />
      <img src={greenArrow} alt="" className="rotate-180" />
      <img src={greenArrow} alt="" />
    </div>
  );
};

export default TitleSpacer;
