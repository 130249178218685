import React from 'react';
import { Link } from 'gatsby';
import { DistrictByID } from './district';
import blueArrow from '../../images/blue-arrow.svg';
import greenArrow from '../../images/green-arrow.svg';
import iconEmail from '../../images/icons/icon-email.svg';
import iconPhone from '../../images/icons/icon-phone.svg';

export const Supe = ({ supervisor, isLinkAvailable, map, election = null }) => {
  const reElection =
    supervisor.nextElection === null
      ? 'Termed out in ' + supervisor.termedOut
      : supervisor.nextElection;

  const link = 'people/' + supervisor.slug + '/';

  // If the next election is within 30 days, add a link to /voter-guide/
  // supervisor.nextElectionDate looks like 11/5/2024
  let electionLink = '';
  console.log(supervisor);
  if (supervisor.nextOfficeElectionDate != null) {
    const today = new Date();
    const nextElectionDate = supervisor.nextOfficeElectionDate;

    if (today < nextElectionDate) {
      // Calculate the difference in days
      const diffTime = Math.abs(nextElectionDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // Check if the next election is within 30 days
      if (diffDays <= 30 && diffDays > 0) {
        electionLink = `/voter-guide/#supervisor-district-${supervisor.district}`;
      }
    }
  }

  return (
    <div className="flex flex-col">
      {electionLink != '' && (
        <div>
          <a href={electionLink}>
            <div
              className={
                map
                  ? 'flex flex-row flex-auto gap-2 bg-brand-green-3 text-black p-2'
                  : 'flex flex-row flex-auto gap-2 bg-brand-green-3 text-black p-2 rounded-md'
              }
            >
              <div>
                This seat is up for election right now!{' '}
                <a className="underline" href={electionLink}>
                  Read our voter guide
                </a>{' '}
                to learn about the candidates.
              </div>
              <img src={blueArrow} className="w-[30px] h-[30px]" />
            </div>
          </a>
        </div>
      )}
      <div className="!bg-white flex flex-col gap-4 md:flex-row md:gap-6 p-5 md:p-6 relative">
        {supervisor.growSfEndorsed ? (
          <img
            src={greenArrow}
            alt=""
            className={
              map
                ? 'absolute w-[30px] h-[30px] md:w-[40px] md:h-[40px] top-2 right-4 md:top-4 md:right-4 rotate-45'
                : 'absolute w-[30px] h-[30px] md:w-[40px] md:h-[40px] -top-2 -right-2 md:-top-3 md:-right-3 rotate-45'
            }
          />
        ) : (
          ''
        )}
        <img
          src={`/supervisors/${supervisor.lastName.toLowerCase()}.jpg`}
          alt={supervisor.fullName}
          className="hidden w-[75px] h-[100px] md:block xsmall-upper-left-clip"
        />
        <div className="flex flex-col gap-4 w-full">
          <div
            className={`flex gap-4 ${supervisor.growSfEndorsed ? 'items-start' : 'items-center'}`}
          >
            <img
              src={`/supervisors/${supervisor.lastName.toLowerCase()}.jpg`}
              alt={supervisor.fullName}
              className="w-[75px] h-[100px] md:hidden xsmall-upper-left-clip"
            />
            <div className="flex flex-col flex-grow w-full gap-0">
              <div className="w-full">
                <p className="text-brand-gray-5 font-bold text-xs uppercase tracking-wider">
                  District {supervisor.district}
                </p>
                <h3 className="text-brand-blue-5 font-bold text-base md:text-xl">
                  {isLinkAvailable(link) ? (
                    <Link to={`/${link}`}>{supervisor.fullName}</Link>
                  ) : (
                    supervisor.fullName
                  )}
                </h3>
              </div>
              <a
                href={`mailto:${supervisor.staffEmail}?bcc=act@growsf.org`}
                className="flex items-center font-bold text-xs md:text-sm text-brand-blue-4 gap-2"
              >
                <img src={iconEmail} alt="" className="w-[16px] h-[12px]" />
                <span>{supervisor.staffEmail}</span>
              </a>
              <a
                href={`tel:+1${supervisor.getRealPhone}`}
                className="flex items-center font-bold text-xs md:text-sm text-brand-blue-4 gap-2"
              >
                <img src={iconPhone} alt="" className="w-[16px] h-[16px]" />
                <span>{supervisor.phone}</span>
              </a>
            </div>
          </div>
          <hr className="border-brand-gray-2" />
          <div className="w-full flex flex-wrap gap-4 columns-2xs">
            {supervisor.wasAppointed && (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Appointed
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{supervisor.tookOffice}</p>
                </div>
              </div>
            )}
            {supervisor.specialElection && (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Special Election
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{supervisor.specialElection}</p>
                  <p>
                    Won by <span className="font-bold">{supervisor.margin}</span> votes
                  </p>
                </div>
              </div>
            )}
            {supervisor.elected && (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Elected
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{supervisor.elected}</p>
                  {!supervisor.specialElection && (
                    <p>
                      Won by <span className="font-bold">{supervisor.margin}</span> votes
                    </p>
                  )}
                </div>
              </div>
            )}
            {supervisor.reElected && (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Re-elected
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{supervisor.reElected}</p>
                </div>
              </div>
            )}
            <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
              <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                Up For Re-Election
              </h2>
              <p className="text-brand-gray-4 text-xs md:text-sm font-light">{reElection}</p>
            </div>
          </div>
          <hr className="border-brand-gray-2" />
          <div className="flex flex-col gap-4">
            <p className="text-brand-gray-4 text-xs md:text-sm">
              {DistrictByID(supervisor.district).description}
            </p>
            {isLinkAvailable(link) ? (
              <Link
                to={`/${link}`}
                className="flex items-center gap-1 uppercase text-xs text-brand-blue-4 tracking-widest font-bold w-fit"
              >
                Learn More
                <img
                  src={blueArrow}
                  alt=""
                  className="transition-transform rotate-45 w-2 h-2 -mt-[1px]"
                />
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
