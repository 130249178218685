import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { SupervisorDistrictFinder } from '../components/districts/supe-district-finder';
import TitleSpacer from '../components/titleSpacer';
import { Supe } from '../components/districts/supe';
import { SupervisorsByName } from '../components/electeds/supervisors.js';
import { SuperButton } from '../components/layout/buttons';
import iconMap from '../images/icons/icon-map.svg';
import { saveAs } from 'file-saver';
import { SignupForm } from '../components/signup';

// NOTE: This file is duplicated because if it's read in via a gatsby query, the file name will change, and I want it to *always* be /sf-supervisor-district-map.png
// If you update the map, be sure to update both paths: static/sf-supervisor-district-map.png and src/images/sf-supervisor-map/sf-supervisor-district-map.png
// (It needs to be in src/images/... too so it can show the right image in the index page preview (ugh))
const mapDownloadPath = '/sf-supervisor-district-map.png';

const DistrictPage = ({ location, data }) => {
  const image = data.previewImage.childImageSharp.resize;
  const availablePersons = data.allMdx.nodes;

  const isLinkAvailable = (slug) => {
    return !!availablePersons.find((ap) => ap.slug === slug);
  };

  const downloadMap = () => {
    const url = mapDownloadPath;
    saveAs(url, 'sf-supervisor-district-map');
  };

  return (
    <Layout>
      <SEO
        title="San Francisco Supervisor District Map"
        description="Explore our Board of Supervisors district map. Learn who your Supervisor is and how to contact them."
        image={image}
        pathname={location.pathname}
      />
      <div className="bg-white">
        <div className=" mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center py-12">
            <h1 className="mt-2 text-3xl max-w-4xl mx-auto leading-8 font-black capitalize tracking-tight text-gray-900 sm:text-6xl">
              Your SF District Supervisor is <span className="text-brand-blue-4">your</span>{' '}
              representative
            </h1>
          </div>
        </div>
      </div>
      <SupervisorDistrictFinder isLinkAvailable={isLinkAvailable} />

      <div className="flex flex-col justify-center items-center bg-white px-5 py-16">
        <span className="mb-2">
          <b>Sign up for GrowSF's weekly roundup of important SF news</b>
        </span>
        <SignupForm formStyle="home" />
      </div>

      <div className="bg-[url('../images/misc/textured-bg-blue.svg')]">
        <div className="mx-auto py-8 px-5 max-w-7xl sm:px-6 lg:px-8 xl:px-0 lg:py-20">
          <div className="flex flex-col items-center gap-2 lg:gap-3">
            <h2 className="text-xs text-center font-bold tracking-wider uppercase">Learn More</h2>
            <TitleSpacer />
            <h3 className="text-center font-bold text-xl lg:font-black lg:text-5xl">
              The Board of Supervisors
            </h3>
          </div>
          <p className="mx-auto max-w-[800px] text-center font-light text-base lg:text-xl text-brand-gray-4 mt-5 lg:mt-6">
            San Francisco is divided into 11 districts, each represented by one member of the Board
            of Supervisors. Enter your address or explore the map to find which district you're in
            and see up-to-date contact information for your Supervisor.
          </p>
          <div className="mt-12 md:mt-16 grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8 md:gap-12">
            <Supe supervisor={SupervisorsByName['Connie Chan']} isLinkAvailable={isLinkAvailable} />
            <Supe
              supervisor={SupervisorsByName['Catherine Stefani']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe
              supervisor={SupervisorsByName['Aaron Peskin']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe
              supervisor={SupervisorsByName['Joel Engardio']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe
              supervisor={SupervisorsByName['Dean Preston']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe supervisor={SupervisorsByName['Matt Dorsey']} isLinkAvailable={isLinkAvailable} />
            <Supe
              supervisor={SupervisorsByName['Myrna Melgar']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe
              supervisor={SupervisorsByName['Rafael Mandelman']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe
              supervisor={SupervisorsByName['Hillary Ronen']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe
              supervisor={SupervisorsByName['Shamann Walton']}
              isLinkAvailable={isLinkAvailable}
            />
            <Supe supervisor={SupervisorsByName['Ahsha Safai']} isLinkAvailable={isLinkAvailable} />
          </div>
          <div className="mt-8 md:mt-12 bg-brand-blue-5 flex flex-col md:flex-row items-center">
            <a href={mapDownloadPath}>
              <img
                src={mapDownloadPath}
                className="object-cover w-full h-full md:max-w-[241px] md:max-h-[157px]"
                alt="San Francisco map of Supervisor districts, updated in 2022"
              />
            </a>
            <div className="p-5 pt-8 md:py-0 md:px-8 flex flex-col md:flex-row justify-between items-center w-full gap-4">
              <div className="space-y-2">
                <h3 className="text-white font-bold text-base md:text-xl text-center md:text-left">
                  Download the San Francisco Supervisor District Map
                </h3>
                <p className="text-xs italic font-light text-brand-blue-1 text-center md:text-left">
                  This Supervisor district map was last updated in April 2022, after redistricting.
                </p>
              </div>
              <SuperButton
                color="green"
                size="sm"
                className="flex items-center justify-center gap-2"
                onClick={downloadMap}
              >
                <img src={iconMap} alt="" className="w-[24px] h-[24px]" />
                Download the map
              </SuperButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DistrictPage;

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { eq: "person" } } }
    ) {
      nodes {
        id
        slug
      }
    }
    previewImage: file(relativePath: { eq: "find-my-supervisor-og.png" }) {
      id
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;
