import React, { useState, useRef, useEffect } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';

const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const DistrictRealMap = ({
  children,
  districtSelectHandler,
  center,
  zoom,
  hasPoint,
  selectedDistricts,
  geoJsonUrl,
  allowClick = true,
}) => {
  const ref = useRef();
  const [map, setMap] = useState(null);
  const [state, setState] = useState({
    hasPoint: false,
  });

  useEffect(() => {
    if (ref.current && !map) {
      let map = new window.google.maps.Map(ref.current, {
        center: { lat: center.lat, lng: center.lng },
        zoom: zoom,
        // Enable "Use Ctrl+Scroll to zoom"
        gestureHandling: 'cooperative',
        disableDefaultUI: true,
      });

      map.data.loadGeoJson(geoJsonUrl);

      if (allowClick) {
        map.data.addListener('click', function (event) {
          map.data.forEach((dt) => {
            if (
              dt.getGeometry().getType() === 'Point' &&
              parseInt(dt.getProperty('district-label')) ===
                parseInt(event.feature.getProperty('district-label'))
            ) {
              dt.getGeometry().forEachLatLng((latlng) => {
                map.setCenter(latlng);
                map.setZoom(zoom);

                return 0;
              });

              return 0;
            }
          });

          districtSelectHandler(event.feature.getProperty('district-label'), 'onClick');
        });
      }

      map.data.addListener('mouseover', function (event) {
        if (event.feature.getGeometry().getType() == 'Point') return false;
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, { fillColor: '#1271A7', fillOpacity: 0.5 });
      });

      map.data.addListener('mouseout', function (event) {
        if (event.feature.getGeometry().getType() == 'Point') return false;
        map.data.revertStyle();
      });

      setMap(map);
    }
  }, [ref, map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    setState({ ...state, hasPoint: true });
    map.setCenter(center);
    map.setZoom(zoom);
  }, [center, zoom]);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.data.setStyle((feature) => {
      if (feature.getGeometry().getType() == 'Point') {
        return {
          label: {
            text: feature.getProperty('district-label'),
            color: '#FFFFFF',
            fontWeight: 700,
          },
          icon: '/districts/icon.png',
        };
      } else {
        let label = window.parseInt(feature.getProperty('district-label'));

        let base = {
          fillColor: '#1E7B6A',
          strokeColor: '#FFFFFF',
          strokeOpacity: 0.7,
          strokeWeight: 3,
          zIndex: 1,
        };
        if (selectedDistricts.includes(label)) {
          base.fillColor = '#1271A7';
          base.fillOpacity = 0.9;
          base.strokeOpacity = 1.0;
          base.strokeWeight = base.strokeWeight * 1.5;
          base.zIndex = 2;
        }
        return base;
      }
    });
  }, [map, selectedDistricts]);

  let marker = null;
  if (hasPoint) {
    marker = <Marker options={{ map: map, position: center, cursor: 'grab' }} />;
  }

  let hasSelectionClass = selectedDistricts.length > 0 ? 'has-selection' : '';

  return (
    <div className={`w-full transition-all ${hasSelectionClass}`}>
      {children}
      <div ref={ref} id="map" className="w-full h-[28rem] md:h-[32rem]" />
      {marker}
    </div>
  );
};

export const DistrictMap = ({ ...mapProps }) => {
  return (
    // This wrapper has to be a separate component because it does async loading.
    <Wrapper apiKey={'AIzaSyDzvx-dBuMNHdeqrIpmZXsQNBqMNFGuPBM'} libraries={['places']}>
      <DistrictRealMap {...mapProps} />
    </Wrapper>
  );
};

export default DistrictMap;
